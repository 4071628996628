main.news:before {
	background-image: url("../img/news.jpg");
	padding-bottom: 22.9%;
}

.presidents-column {
	font-family: $font-opensans;
	
	.sidebar {
		width: 27%;
		display: inline-block;
		border: 1px solid transparent;
		vertical-align: top;
		font-size: 1.2em;
		
		img {
			width: 100%;
		}
		h1, .credit {
			font-size: 1.5rem;
			font-weight: bold;
			margin-bottom: 0;
			font-family: $font-garamond;
		}
		h2 {
			font-family: $font-opensans;
			font-weight: bold;
			font-size: 1.3rem;
			color: black;
			margin: 1.5rem 0 1rem 0;
			text-transform: uppercase;
		}
		ul {
			list-style: none;
			padding: 0;
			font-size: 1.05rem;
			line-height: 1.5;
		}
		.deleted {
			background-color: #ddd;
			opacity: 0.7;
		}
	}
	
	.main {
		width: 63%;
		display: inline-block;
		border: 1px solid transparent;
		margin-left: 5%;
		vertical-align: top;
		font-family: $font-opensans;
		
		&.deleted {
			background-color: #ddd;
			opacity: 0.7;
		}
		
		p {
			font-size: 1.2rem;
		}
	}
}

.letterBody {
	margin-top: 1.5em;
}