.index main {
	padding-left: 0;
	padding-right: 0;
	font-size: 0;
}

/* slideshow */
.slideshowPlaceholder {
	text-align: center;

	img {
		width: 100%;
	}
}

.slideshowWrapper {
	padding: 10px 0 0 0;
	display: none;
}

.slideshowAdmin {
	display: none;

	table {
		border-collapse: collapse;
	}

	td {
		padding: 10px;

		&:first-child {
			max-width: 400px;
		}

		&:nth-of-type(2) {
			padding: 0 2em;
		}
	}
	img {
		width: 100%;
		padding: 0;
	}
	i {
		padding: 0 0.5em;
	}
	table tr:first-child .icon-up, table tr:last-child .icon-down {
		cursor: not-allowed;
		opacity: 0.5;
	}
	.deleted {
		background: inherit;

		img {
			opacity: 0.4;
		}
	}
}

#newPhotoDropZone {
	margin: 20px 0 20px 10px;
	padding: 20px;
	border: 4px double blue;
	background-color: #70dbff;
	background: white;
	border-radius: 10px;
	cursor: pointer;
}
.fd-zone {
	position: relative;
	overflow: hidden;
	/* The following are not required but create a pretty box: */
	width: 15em;
	margin: 0 auto;
	text-align: center;
}

/* Hides <input type="file"> while simulating "Browse" button: */
.fd-file {
	opacity: 0;
	font-size: 118px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
	padding: 0;
	margin: 0;
	cursor: pointer;
	filter: alpha(opacity=0);
	font-family: sans-serif;
}

/* Provides visible feedback when use drags a file over the drop zone: */
.fd-zone.over { border-color: maroon; background: #eee; }


main > .flexslider, .slideshowWrapper {
	width: calc(100% + 180px);
	margin-left: -90px !important;
	
	@media (max-width: $width-mobileMenu) {
		width: calc(100% + 30px);
		margin-left: -15px !important;
	}
}

.flex-pauseplay a.flex-pause, .flex-pauseplay a.flex-play {
	height: 28px;
}
.flex-direction-nav a {
	height: 45px !important;
}
	
/* intro */
.intro {
	text-align: center;
	font-size: 1.5rem;
	
	@media (max-width: 600px) {
		font-size: 1.2rem;
	}

	@media (max-width: 470px) {
		font-size: 1.0rem;
	}

	h2 {
		color: #b06d13;
		font-weight: normal;
		font-style: normal;
		font-family: #{$font-opensans};
		font-size: 3rem;
		
		@media (max-width: 600px) {
			font-size: 2.4rem;
		}

		@media (max-width: 470px) {
			font-size: 1.9rem;
		}

		@media #{$mq-smallest} {
			font-size: 1.6rem;
			/* width: 7.5em;
			margin-left: auto;
			margin-right: auto; */
		}
	}
	p {
		color: #413823;
		font-family: #{$font-opensans};
		width: 65%;
		margin: 0 auto 1.2rem auto;
		
		
		@media (max-width: 800px) {
			width: 80%;
		}
		@media #{$mq-smallest} {
			font-size: 1rem;
			width: 90%;
		}
	}
}

/* events */
.index .events {
	font-size: 0;
	text-align: center;

	article {
		width: calc(25% - 7.5px);
		display: inline-block;
		box-sizing: content-box;
		margin: 0;
		padding: 0 10px 3rem 0;
		vertical-align: top;
		font-size: 1rem;
		text-align: left;
		
		&:nth-child(4n) {
			padding-right: 0;
		}
		
		@media (max-width: 800px) {
			width: calc(33.3% - 6.7px);

			&:nth-child(4n) {
				padding-right: 10px;
			}

			&:nth-child(3n) {
				padding-right: 0;
			}
		}
		@media (max-width: 500px) {
			width: calc(50% - 5px);

			&:nth-child(3n) {
				padding-right: 10px;
			}

			&:nth-child(2n+0) {
				padding-right: 0;
			}
		}
		@media #{$mq-smallest} {
			width: 100%;
			display: block;
			padding-bottom: 2rem;
		}

		/*
		img {
			width: 100%;
			margin: 0 0 10px 0;
		}
		*/
	
		h2, time, div {
			margin-left: 5%;
		}
	
		h2 {
			padding: 0 0 0.1rem 0;
			font-family: #{$font-opensans};
			font-weight: bold;
			font-size: 1rem;
			text-transform: uppercase
		}
	
		time {
			font-family: #{$font-opensans};
			font-weight: bold;
		}
	
		div {
			text-transform: none;
			font-family: #{$font-opensans};
			line-height: 1.4;
			cursor: pointer;
		}
	
		a {
			font-family: #{$font-opensans};
			font-weight: bold;
			color: black;
		}
	}

	.eventImageContainer {
		width: 100%;
		margin: 0 0 10px 0;
		overflow: hidden;
		display: inline-block;
		position: relative;
		
		&:after {
			content: '';
			display: block;
			padding-bottom: 71.7%;
		}
	}
	
	.eventImageContainer img {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	
	.eventImageContainer img.lazyloaded {
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;
	}

}

.index .announcements {
	font-size: 0;
	text-align: center;

	article {
		width: 100%;
		display: block;
		box-sizing: content-box;
		margin: 0 0 3rem 0;
		vertical-align: top;
		font-size: 0;
		text-align: left;
		font-family: #{$font-opensans};
		background-color: #d9e9e9;
		
		@media #{$mq-smallest} {
			padding-bottom: 2rem;
		}
		
		div {
			display: inline-block;
			vertical-align: top;
			box-sizing: border-box;

			@media (max-width: 600px) {
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				display: block;
			}
		}
		
		.anncImage {
			width: 30%;
			max-width: 350px;
			
			@media (max-width: 600px) {
				width: 100%;
			}
			
			img {
				width: 100%;
			}
		}
		
		.anncDesc {
			width: 69%;
			font-size: 1rem;
			padding: 2em;

			@media (max-width: 600px) {
				width: 100%;
			}
		}
	}

	.eventImageContainer {
		width: 100%;
		margin: 0 0 10px 0;
		overflow: hidden;
		display: inline-block;
		position: relative;
		
		&:after {
			content: '';
			display: block;
			padding-bottom: 71.7%;
		}
	}
	
	.eventImageContainer img {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	
	.eventImageContainer img.lazyloaded {
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;
	}

}
