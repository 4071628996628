main.events, main.announcements {
	h2, table.month, nav.month, article, .snippet, .cke, form, ul.messages {
		width: 84%;
		margin-left: auto;
		margin-right: auto;
	}

	ul.messages {
		padding: 20px;
		color: red;
	}

	h3 {
		margin-top: 0;
		margin-bottom: 0;
		text-align: center;
	}

	nav.month {
		background: none;
		margin-bottom: 40px;
		border: none;

		.linkPrev {
			float: left;
		}

		.linkNext {
			float: right;
		}
	}

	.noEvents {
		width: 84%;
		display: block;
		margin: 70px auto auto auto;
		font-style: italic;
		font-size: 120%;
		text-align: center;
		padding: 40px;
		background: #f6f1dd;
	}

	.list {
		display: none;
	}

	@media (max-width: 1000px) {
		padding-left: 50px;
		padding-right: 50px;
	}
	@media (max-width: 900px) {
		.month {
			width: 100%;
		}
	}
	@media (max-width: 800px) {
		padding-left: 20px;
		padding-right: 20px;
	}
	@media (max-width: 700px) {
		.month .month-grid, .formatLink {
			display: none;
		}
		.list {
			display: block;
		}
	}

	&:before {
		background-image: url("../img/events.jpg");
		padding-bottom: 22.9%;
	}

	h2 {

		a {
			font-size: 22px;
			margin-left: 1em;
		}
	}

	.monthNav {
		th {
			font-size: 1.6rem;
			text-align: left;
			padding: 1.5rem 0.5rem 2rem 0.5rem;
			color: black;

			@media (max-width: 500px) {
				font-size: 1.2rem;
			}

			@media (max-width: 400px) {
				font-size: 0.9rem;
			}

			a {
				color: #{$color-green};
			}
		}

		hr {
			margin-top: 2rem;
			border-top: 2px solid $color-orange;
		}
	}

	.month {
		border-collapse: collapse;
		font-size: 1.0rem;
		color: #{$color-green};
		background-color: #f7f2dd;
		border: 10px solid #f7f2dd;

		th, td {
			padding: 0 5px 10px 5px;
		}

		.weekdays th {
			text-align: left;
		}

		.weekdays th, td {
			width: 14.14%;
			vertical-align: top;
			border-left: 2px solid $color-green;

			&:first-child {
				border: none;
			}
		}

		td {
			height: 7rem;
		}

		.dayNumber {
			text-align: left;
		}

		.offMonth .dayNumber {
			color: #aaa;
		}
	}

	.month-list {
		background-color: transparent;
		border: none;
	}

	.disabled {
		color: black;
		opacity: 0.4;
	}

	article {
		padding: 1rem 1.3rem;
		background-color: #f6f1dd;
		margin-bottom: 1rem;
		font-family: #{$font-opensans};
		font-size: 1.3rem;
		font-weight: bold;
		position: relative;

		@media (max-width: 550px) {
			font-size: 1.0rem;
		}

		@media (max-width: 440px) {
			font-size: 0.9rem;
		}

		&.deleted {
			background-color: #eeeeee;
			opacity: 0.7;
		}

		h3 {
			font-size: 150%;
			margin-top: 0;
			margin-bottom: 10px;
			display: inline-block;
		}

		.deletedLabel {
			position: absolute;
			right: 1rem;
			color: $color-orange;
		}

		h4 {
			font-size: 100%;
			margin-top: 0;
			margin-bottom: 4px;
		}

		div {
			display: block;
			font-size: 80%;
			margin-bottom: 10px;
			font-weight: normal;

			&:first-of-type {
				margin-top: 20px;
			}
		}

		.actions {

			a {
				display: inline-block;
				margin-left: 1.5em;
			}
		}

		img {
			/* height: 200px; */

			@media (max-width: 380px) {
				width: 100%;
				height: auto;
			}
		}
	}

	.fft td {
		vertical-align: top;

		&:nth-of-type(2) {
			padding-left: 1.25em;
		}
	}

	.swatch {
		display: inline-block;
		width: 2em;
		height: 1em;
		margin-right: 1em;
	}

	#eventImageContainer {
		/*
		width: 251px;
		height: 180px;
		overflow: hidden;
		*/
		display: block;
		width: 100%;
		text-align: left;
		margin-bottom: 1em;
		/*
		border: 1px solid #a9a9a9;
		*/
	}

	#eventImage {
		/*
		max-width: 251px;
		max-height: 180px;
		*/
		max-width: 80%;
		max-height: 400px;
		/*
		position: relative;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		*/
	}

	#cropPreviewContainer {
		margin: 0 0 1em 1em;
	}

	form.edit label > span:first-child {
		width: 11em;
	}

	form.edit button {
		margin: 0 0 1em 12em;
	}

	ul.parsley-errors-list {
		margin: 0 0 0 9em;
	}

	#personSelect:not([disabled]), #personEmail:not([disabled]) {
		color: red;

		&.usable {
			color: green;
		}
	}

	i[class*="icon-"].disabled {
		transition: unset;
		cursor: default;

		&:hover {
			color: inherit;
		}
	}
}

.qtip.qtip-events {
	font-size: 1.5rem;
	line-height: 100%;
	background-color: #d7be57;
	padding: 0.7rem;
	max-width: 30rem;
	width: 25rem;

	.eventTooltip {
		h1 {
			font-size: 1.9rem;
			font-weight: bold;
			font-family: $font-opensans;
			color: #413823;
			margin-top: 0;
			line-height: 1.1;
		}

		.deletedLabel {
			float: right;
			color: $color-orange;
		}

		h2 {
			font-size: 1.2rem;
			font-weight: bold;
			font-family: $font-opensans;
			color: #413823;
		}

		p {
			font-size: 1.2rem;
			font-weight: normal;
			font-family: $font-opensans;
			color: #413823;
		}
	}
}


main.announcements {
	article {
		img, div {
			display: inline-block;
			vertical-align: top;
		}
	}
}

table.list.registrations {
	display: block;
	&[v-cloak] {
		display: none;
	}
	&[v-cloak]::before {
		content: "Loading...";
	}
	td {
		border-width: 1px 0 0 0;
		border-style: dashed;
		border-color: #333333;
		vertical-align: top;
	}

	tbody tr:first-of-type td, tfoot tr:last-of-type td, tr.notes td {
		border: none;
	}

	tr.notes {
		display: none;
	}

	textarea[name=notes] {
		margin-left: 25px;
		width: calc(100% - 25px);
		min-width: 20em;
	}

	.noNotes {
		color: #ccc;
	}
}
