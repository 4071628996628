main.contact:before {
	background-image: url("../img/contact.jpg");
	padding-bottom: 22.9%;
}

/*
main.contact {
	line-height: 1.2;
		
	div {
		zfloat: left;
		margin-left: 7%;
	}
	img {
		float: right;
		margin-right: 7%;
	}
}
*/