.instructors {
	table {
		border-collapse: collapse;
		margin: 50px auto;
		font-size: 1.2rem;
	}

	th, td {
		text-align: left;
		padding: 10px;
		border-bottom: 1px dashed #333;
	}

	td.cost {
		text-align: right;
	}

	td.links {
		font-size: 0.9rem;
	}

	article {
		padding: 1rem 1.3rem;
		background-color: #fbf2ea;
		margin-bottom: 1rem;
		font-family: #{$font-opensans};
		font-size: 1.3rem;
		font-weight: bold;
		
		@media (max-width: 500px) {
			padding: 0.5rem;
		}
		
		&.deleted {
			background-color: #eeeeee;
			opacity: 0.7;
		}
		
		h3, h4 {
			margin-top: 0;
			margin-bottom: 10px;
		}
		
		h3 {
			font-size: 120%;
			position: relative;
		}
		
		.deletedLabel {
			position: absolute;
			right: 0;
			color: $color-orange;
		}
		
		h4 {
			font-size: 85%;
			margin-top: 20px;
		}
		
		ul {
			margin-top: 0;
			list-style-type: none;
			padding-left: 10px;
			font-size: 80%;
			
			li {
			}
				
		}
		
		time, div {
			display: block;
			font-size: 70%;
			margin-bottom: 10px;
		}
		
		button {
			font-size: 100%;
			
			icon {
				margin-left: 1em;
				margin-right: 0.5em;
			}
		}
		
		.actions {
			margin-left: 0.5em;
			
			@media (max-width: 500px) {
				display: block;
				float: right;
				
				& > a, .enrollControl {
					display: block;
				}
			}
			
			a {
				display: inline-block;
				margin-left: 0.85em;
			}
		}
	}
	
	.datatable.instructors {
		width: auto;
	}

}