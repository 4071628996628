main.volunteer:before {
	background-image: url("../img/volunteer.jpg");
	padding-bottom: 22.9%;
}
main.volunteer {
	font-size: 1.2rem;

	@media (max-width: 550px) {
		font-size: 1.0rem;
	}


	.spotlight {
		width: 45%;
		display: inline-block;
		float: left;
		padding: 30px;
		box-sizing: border-box;
		background-color: #ecebe9;
		font-family: #{$font-opensans};
		text-align: center;
		margin-top: 40px;
			
		@media (max-width: 550px) {
			padding: 10px;
		}

		@media (max-width: 450px) {
			width: 100%;
			display: block;
			float: none;
			margin-top: 0;
		}

		h1 {
			font-weight: bold;
			font-size: 1.8rem;
			margin-bottom: 0.4rem;
			
			@media (max-width: 550px) {
				font-size: 1.4rem;
			}
		}
		
		.subtitle {
			font-weight: bold;
			
			@media (max-width: 550px) {
				font-size: 1.0rem;
			}
		}
		
		.intro {
			font-weight: normal;
			width: 70%;
			margin: 2rem auto 1rem auto;
			font-size: 1.2rem;
			
			@media (max-width: 550px) {
				width: 90%;
				font-size: 1.0rem;
			}
		}
		
		img {
			max-width: 90%;
			height: auto;
			margin: auto;
			
			/*
			@media (max-width: 550px) {
				width: 90%;
			}
			*/
		}

		.credit {
			font-style: italic;
			margin: 1.5rem auto 1rem auto;
			
			@media (max-width: 550px) {
				font-size: 1.0rem;
			}
		}
		
		a {
			font-size: 1.1rem;
			
			@media (max-width: 550px) {
				font-size: 0.9rem;
			}
		}
	}

	.main {
		width: 50%;
		display: inline-block;
		float: left;
		margin-left: 5%;
		margin-top: 40px;
		
		@media (max-width: 550px) {
			margin-top: 50px;
		}

		@media (max-width: 450px) {
			width: 90%;
			display: block;
			float: none;
		}

		
		img {
			max-width: 90%;
			height: auto;
			margin: auto;
		}
	}
}