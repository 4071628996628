main.donate {
	form {
		margin-left: auto;
		margin-right: auto;
		font-size: 0;
		
		fieldset {
			padding: 10px 0 5px 0;
			
			legend {
				font-size: 1.5rem;
				text-decoration: none;
				padding: 0 0.5em;
			}
			
			fieldset {
				padding: 0;
				border: 0;
			}
		}
		
		label {
			clear: left;
			font-size: 1.2rem;
			display: inline-block;
			width: 8em;
			vertical-align: top;
			
			@media (max-width: 500px) {
				margin-left: 0.5em;
			}
		}
		
		.inputWrapper {
			display: inline-block;
			font-size: 1.2rem;
			width: 18em;
			margin-left: 1em;
			margin-right: 0.5em;

			@media (max-width: 500px) {
				font-size: 1.0rem;
				width: 16em;
				margin-left: 0.5em;
			}
		}
		
		input:not([type=checkbox]):not([type=radio])  {
			width: 18em;

			@media (max-width: 500px) {
				width: 16em;
			}
		}
	
		.inputNote {
			font-size: calc(1.2rem * 0.7);
			width: calc(18em / 0.7);
			zmargin-left: calc(8em / 0.7);
			margin-top: -4px;
			margin-bottom: 5px;
			line-height: 1.2;
			display: block;
			
			@media (max-width: 500px) {
				font-size: calc(1.0rem * 0.7);
				width: calc(16em / 0.7);
			}
		}
	
		textarea {
			width: 100%;
			max-width: 30em;
		}
		
	}
	
	#donation_kind, #notes {
		label {
			width: 95%;
			display: block;
		}
	}
	
	.divider {
		width: 96%;
		margin-left: auto;
		margin-right: auto;
		border-bottom: 1px inset #aaa;
		height: 1px;
		margin-top: -25px;
		margin-bottom: 15px;
	}
	
	legend {
		color: $color-orange;
	}

	.hasError {
		border: 1px solid red;
	}
		
	#payment_errors {
		font-size: 1.5rem;
		color: red;
		display: none;
		margin-left: 2rem;
		margin-bottom: 10px;
	}
	
	#stripeStatus {
		font-size: 1.5rem;
		color: $color-orange;
		margin-left: 1rem;
		margin-top: 10px;
		display: none;
		
	}
	
	.stripeError {
		color: red;
	}
	
	.cardSecurityNote {
		margin: -10px 1.7em 1em 1.7em;
		font-size: calc(1.2rem * 0.7);
		font-family: #{$font-opensans};
		color: $color-orange;
	}
}