main.classrooms {
	&:before {
		background-image: url("../img/courses.jpg");
		padding-bottom: 22.9%;
	}

	h2, & > p, .snippet, .cke, ul, .list, .add, .edit {
		width: 84%;
		margin-left: auto;
		margin-right: auto;

		@media (max-width: 500px) {
			width: 100%;
		}
	}

	/*
	h2 {
		line-height: 110%;
		font-size: 1.8rem;

		@media (max-width: 500px) {
			font-size: 25px;
		}
	}
	*/
	.edit label {
		margin-left: 0;
		width: 100%;
	}
	.list {
		margin-bottom: 20px;
	}
	.edit button {
		margin-top: 20px;
	}
	tbody {
		border-color: $color-green;
		border-style: solid;
		border-width: 3px
	}
	tbody.active.hasDeleted {
		border-bottom-width: 0;
	}
	tbody.deleted.hasActive {
		border-top-width: 0;
	}
	td.actions {
		white-space: nowrap;
		text-align: center;
	}
	tr:first-of-type .icon-up, tr:last-of-type .icon-down {
		opacity: 0.3;
		cursor: default;
		
		&:hover {
			color: inherit;
		}
	}
	.fullWidth {
		width: 100%;
		max-width: 30em;
	}
	tr.deleted {
		background-color: #ccc;
	}

	article {
		padding: 1rem 1.3rem;
		background-color: #fbf2ea;
		margin-bottom: 1rem;
		font-family: #{$font-opensans};
		font-size: 1.3rem;
		font-weight: bold;
		
		/*
		&:hover {
			background-color: #e8b582;
		}
		*/
		
		h3, h4 {
			margin-top: 0;
			margin-bottom: 10px;
			font-weight: normal;
		}
		
		h3 {
			font-size: 120%;
		}
		
		#mapWrap {
			font-weight: normal;
		}
		
		.actions {
			margin-left: 0.5em;
			
			a {
				display: inline-block;
				margin-left: 0.85em;
			}
		}
		
	}
	ul {
		margin-top: 0;
	}
}