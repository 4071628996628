main.people {
	table.list {
		font-size: 1rem;

		tr.deleted {
			background-color: #ddd;
			opacity: 0.7;
		}
	}

	td.icon {
		font-size: 0;

		 div {
			font-family: "actions";
			text-align: center;

			i[class*="icon-"] {
				font-size: 1rem;
				transition: none;
				cursor: initial;

				&:hover {
					color: inherit;
				}
			}

		 	.personTypes {
				border: 1px solid #999;
				border-radius: 4px;
				padding: 2px;
				white-space: nowrap;
			}
		}
	}

	td.actions {
		white-space: nowrap;
		text-align: center;
	}

	form.edit {
		i[class*="icon-"] {
			font-size: 1.3rem;
			margin-left: 1em;
			cursor: pointer;
		}

		input[type=text], input[type=password], input[type=email] {
			width: 20em;
		}
	}

	div.noRecord {
		color: #999;
		display: none;
	}

	i.icon-add:not(#phoneAdd) {
		display: none;

		&.noRecord {
			display: inline-block;
		}
	}

	i.icon-delete:not(.phoneControl) {
		display: none;
	}

	form.claim {
		label span {
			width: 11em;
		}

		div {
			margin-left: 1em;
			color: $color-orange;
		}
	}

	#peopleFilter, #addressFilter, #membershipFilter, #deletedFilter, #generatedFilter {
		margin-bottom: 10px;

		label {
			margin-right: 1em;

			span {
				margin-left: 0.3em;
			}
		}
	}

	.messages {
		th:nth-child(5), td:nth-child(5),
		th:nth-child(6), td:nth-child(6),
		th:nth-child(7), td:nth-child(7) {
			text-align: right;
		}

		tr.details {
			display: none;

			td {
				height: 0;

				& > div {
					display: none;
					float: right;
				}
			}
		}

		tr.expanded:not(.details) td {
			border-bottom: none;
		}
	}
}