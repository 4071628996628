$font-path: "../font/";

@font-face {
	font-family: "garamond";
	src: url("#{$font-path}garamond/ebgaramond-webfont.eot");
	src: url("#{$font-path}garamond/ebgaramond-webfont.eot?#iefix") format("embedded-opentype"),
	     url("#{$font-path}garamond/ebgaramond-webfont.woff") format("woff"),
	     url("#{$font-path}garamond/ebgaramond-webfont.ttf") format("truetype"),
	     url("#{$font-path}garamond/ebgaramond-webfont.svg#eb_garamondregular") format("svg");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "opensans";
	src: url("#{$font-path}opensans/regular/opensans-400.eot?#iefix") format("embedded-opentype"),
	     url("#{$font-path}opensans/regular/opensans-400.woff") format("woff"),
	     url("#{$font-path}opensans/regular/opensans-400.ttf")  format("truetype"),
	     url("#{$font-path}opensans/regular/opensans-400.svg#opensans-400") format("svg");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "opensans";
	src: url("#{$font-path}opensans/bold/opensans-700.eot?#iefix") format("embedded-opentype"),
	     url("#{$font-path}opensans/bold/opensans-700.woff") format("woff"),
	     url("#{$font-path}opensans/bold/opensans-700.ttf")  format("truetype"),
	     url("#{$font-path}opensans/bold/opensans-700.svg#opensans-700") format("svg");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "trajanpro";
	src: url("#{$font-path}trajanpro-regular/trajanpro-regular.eot?#iefix") format("embedded-opentype"),
	     url("#{$font-path}trajanpro-regular/trajanpro-regular.otf")  format("opentype"),
	     url("#{$font-path}trajanpro-regular/trajanpro-regular.woff") format("woff"),
	     url("#{$font-path}trajanpro-regular/trajanpro-regular.ttf")  format("truetype"),
	     url("#{$font-path}trajanpro-regular/trajanpro-regular.svg#trajanpro-regular") format("svg");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "trajanpro";
	src: url("#{$font-path}trajanpro-bold/trajanpro-bold.eot?#iefix") format("embedded-opentype"),
	     url("#{$font-path}trajanpro-bold/trajanpro-bold.otf") format("opentype"),
	     url("#{$font-path}trajanpro-bold/trajanpro-bold.woff") format("woff"),
	     url("#{$font-path}trajanpro-bold/trajanpro-bold.ttf") format("truetype"),
	     url("#{$font-path}trajanpro-bold/trajanpro-bold.svg#trajanpro-bold") format("svg");
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: "actions";
	src: url("#{$font-path}actions/actions.eot");
	src: url("#{$font-path}actions/actions.eot#iefix") format("embedded-opentype"),
	     url("#{$font-path}actions/actions.woff2") format("woff2"),
	     url("#{$font-path}actions/actions.woff") format("woff"),
	     url("#{$font-path}actions/actions.ttf") format("truetype"),
	     url("#{$font-path}actions/actions.svg#actions") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "garamond";
		src: url("#{$font-path}garamond/ebgaramond-webfont.svg#eb_garamondregular") format("svg");
		font-weight: normal;
		font-style: normal;
	}
	@font-face {
		font-family: "opensans";
		src: url("#{$font-path}opensans/regular/opensans-400.svg#opensans-400") format("svg");
		font-weight: normal;
		font-style: normal;
	}
	@font-face {
		font-family: "opensans";
		src: url("#{$font-path}opensans/bold/opensans-700.svg#opensans-700") format("svg");
		font-weight: bold;
		font-style: normal;
	}
	
	@font-face {
		font-family: "trajanpro";
		src: url("#{$font-path}trajanpro-regular/trajanpro-regular.svg#trajanpro-regular") format("svg");
		font-weight: normal;
		font-style: normal;
	}
	@font-face {
		font-family: "trajanpro";
		src: url("#{$font-path}trajanpro-bold/trajanpro-bold.svg#trajanpro-bold") format("svg");
		font-weight: bold;
		font-style: normal;
	}
}
