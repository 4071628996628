/* global */

* {
	box-sizing: border-box;
}
html, body {
	zheight: 100%;
}
body {
	background-color: #b3cfd1;
	font-size: 62.5%;
	max-width: 1200px;
	margin: 0 auto 0 auto;
	position: relative;

	&.printable {
		background-color: white;
		margin: 1em;

		h2 {
			font-size: 2.2rem;
		}
		h3 {
			font-size: 1.8rem;
			margin: 0;
			text-decoration: underline;
		}
		* {
			font-size: 1.2rem;
			line-height: 1.3;
		}
	}

/*
	&.hasFixedHeader {
		margin-top: calc(2.7rem + 3px);
	}
*/

	& > * {
		background-color: #fff;
		width: 100%;
		font-size: 1.16rem;
		line-height: 1.5;
		padding: 0;
		overflow: visible;
	}
}
.clearfix {
	&:before, &:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
	*zoom: 1;
	clear: left;
}
a {
	text-decoration: none;
	color: $color-green;
	transition: color 0.2s ease-in;

	&:hover {
		color: #{$color-orange};
	}
}
main a.current {
	color: #{$color-green};
}
h2 {
	font-family: #{$font-garamond};
	font-weight: bold;
	font-size: 2.2rem;
	color: #{$color-orange};
	margin: 0;

	@media(max-width: $width-mobileMenu) {
		font-size: 1.8rem;
		line-height: 110%;
	}

	@media (max-width: 500px) {
		font-size: 25px;
	}
}
.note {
	color: $color-orange;
}

/* admin menu */
#adminControls {
	position: fixed;
	z-index: 5;
	background: none;
	pointer-events: none;

	&.active {
		.handle {
			opacity: 1;
			top: -1px;
		}
	}

	nav {
		display: none;
		border: solid $color-green;
		border-width: 0 1px 1px 1px;
		background: $color-orange;
		padding: 10px 20px;
		pointer-events: all;

		a {
			margin-left: 20px;

			&:hover {
				color: black;
			}

			&:first-child {
				margin-left: 0;
			}
		}
	}

	.handle {
		position: relative;
		display: inline-block;
		padding: 0 7px;
		color: $color-green;
		border: solid $color-green;
		border-width: 0 1px 1px 1px;
		border-radius: 0 0 10px 10px;
		margin-left: 20px;
		background: $color-orange;
		opacity: 0.6;
		cursor: pointer;
		transition: opacity 0.2s ease-in;
		pointer-events: all;

		&:hover {
			opacity: 1;
		}
	}

}
/*
header.fixed {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
	height: 2.7rem;
	background-color: $color-green;
	padding: 4px;
	z-index: 999;
	font-size: 1rem;
	box-shadow: 0 3px 2px -1px $color-orange;
	overflow: hidden;
	color: white;

	a {
		font-weight: bold;
		color: $color-orange;
		display: inline-block;
		margin: 7px 0 0 10px;

		&:hover {
			color: white;
		}
	}
}
*/
/* header */
header.main {
	position: relative;
	text-transform: uppercase;
	overflow: visible;
	background: white;
	float: left;
	padding: 0 30px;

	@media (max-width: $width-mobileMenu) {
		padding: 0;
	}

	h1 {
		padding: 0;
		margin: 20px 15px 0 5px;
		clear: both;
		font-family: #{$font-trajanpro};
		color: $color-green;
		font-size: 2.5rem;
		float: left;
		letter-spacing: 8px;

		@media (max-width: 1080px) {
			font-size: 2.2rem;
			letter-spacing: 7px;
		}
		@media (max-width: 990px) {
			font-size: 1.7rem;
			letter-spacing: 6px;
		}
		@media (max-width: 830px) {
			font-size: 1.45rem;
			letter-spacing: 4px;
		}
		@media (max-width: 680px) {
			font-size: 1.2rem;
			letter-spacing: 3px;
		}
		@media #{$mq-smallest} {
			font-size: 1.0rem;
			letter-spacing: 2px;
		}

		.larger {
			font-size: 116%;
		}

		&:before {
			content: " ";
			display: block;
			position: relative;
			top: 0px;
			height: 54px;
			width: 278px;
			max-width: 90%;
			background-image: url("../img/swash.png");
			background-repeat: no-repeat;
			background-size: contain;

			@media #{$mq-smallest} {
				position: relative;
				top: -6px;
				background-position-x: 50%;
			}
		}
	}

	nav {
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			font-size: 0;
		}

		li {
			display: inline-block;
		}
	}
}

.memberDetails {
	padding: 0;
	margin: 50px 0 0 0;
	clear: right;
	font-family: #{$font-opensans};
	color: #454545;
	font-size: 1.0rem;
	float: right;
	text-align: right;
	text-transform: none;

	@media (max-width: 830px) {
		margin-right: 15px;
	}

	@media (max-width: 600px) {
		clear: left;
	}

	@media (max-width: $width-mobileMenu) {
		//margin-top: 15px;
		padding-bottom: 10px;
	}

	.username {
		font-style: italic;
	}

	.enrollmentsLink, .expires a {
		color: $color-orange;

		&:hover {
			color: $color-green;
		}
	}

	.expiresSoon {
		font-weight: bold;
	}
}

.hideOnMobile {
	@media (max-width: $width-mobileMenu) {
		display: none !important;
	}
}

.hideOnDesktop {
	@media (min-width: $width-desktopMenu) {
		display: none !important;
	}
}

.nav1 {
	position: absolute;
	right: 0px;

	/*
	@media (max-width: $width-mobileMenu) {
		position: relative;
		float: none;
		width: 100%;
		right: initial;
	}
	*/

	label {
		display: none;
		cursor: pointer;
		text-align: center;
		color: $color-green;
		font-weight: bold;
		font-size: 1.2rem;
		padding: 7px 0 5px 0;


		/*
		@media (max-width: $width-mobileMenu) {
			display: block;
		}
		*/
	}

	/*
	ul {
		@media (max-width: $width-mobileMenu) {
			display: none;
		}
	}
	*/

	li {
		background-color: $color-green;
		padding: 5px 11px;
		border-right: 1px solid $color-gold;
		font-size: 1.1rem;

		@media (max-width: 820px) {
			font-size: 0.9rem;
			padding: 5px 8px;
		}
		@media (max-width: 670px) {
			font-size: 0.7rem;
		}
		/*
		@media (max-width: $width-mobileMenu) {
			padding: 5px;
			width: 100%;
			text-align: center;
			font-size: 1.2rem;
			border: none;
		}
		*/

		&.search {
			background-color: $color-gold;
			cursor: pointer;
			color: #b06d13;
		}
		&.search span {
			display: inline-block;
			margin-right: 0.5em;
			font-size: 95%;
		}
	}
	a {
		font-family: #{$font-opensans};
		color: $color-gold;
	}
}
#nav1-toggle {
	display: none;
}
#nav1-toggle:checked + .nav2-ul {
	display: flex;
}


.nav2 {
	clear: both;
	float: left;
	margin-top: 25px;
	position: relative;
	width: 100%;
	z-index: 2;

	@media (max-width: $width-mobileMenu) {
		padding-left: 0;
		width: 100%;
		float: none;
		background-color: #b3cfd1;
	}

	label:not(.submenuLabel) {
		display: none;
		cursor: pointer;
		text-align: center;
		color: $color-green;
		font-weight: bold;
		font-size: 1.2rem;
		padding: 7px 0 5px 0;


		@media (max-width: $width-mobileMenu) {
			display: block;
		}
	}
}

.nav2-ul {
		display: flex;
		flex-wrap: wrap;

		@media (max-width: $width-mobileMenu) {
			display: none;
		}

	li {
		@media (max-width: $width-mobileMenu) {
			width: 100%;
			display: block;
			text-align: center;
		}
	}

	a, .submenuLabel {
		font-family: #{$font-opensans};
		font-size: 0.95rem;
		font-weight: bold;
		color: black;
		padding: 0 0.5rem;

		&:hover {
			color: $color-green;
		}

		@media (max-width: 930px) {
			font-size: 0.80rem;
		}

		@media (max-width: 830px) {
			font-size: 0.8rem;
		}
		@media (max-width: $width-mobileMenu) {
			font-size: 1.2rem;
			line-height: 2.0;
		}

		&.current {
			color: $color-green;
		}
	}

	li:first-child a {
		padding-left: 0;
	}

	.dropdown_content {
		text-transform: none;
		line-height: 1.4;
		position: absolute;
		background-color: $color-green;
		margin-left: -30px;
		width: calc(100% + 60px);
		left: 0;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		//transition: .75s;
		transition: 0s;
		max-height: 0;
		overflow: hidden;
		padding-left: 14px;
		margin-top: -1px;

		@media(max-width: $width-mobileMenu) {
			width: 100%;
			margin-left: 0;
			margin-top: -5px;
		}

		a {
			font-size: 0.95rem;
			color: white;
			padding: 12px 16px;
			text-decoration: none;
			display: inline-block;

			@media (max-width: 820px) {
				font-size: 0.80rem;
			}

			&:hover {
				color: $color-orange;
			}

			&.current {
				color: $color-orange;
			}
		}

	}
	.cog {
		margin-left: auto;

		img {
			width: 15px;
			opacity: 0.2;

			&:hover {
				transform: rotate(22.5deg);
			}
		}
	}
}
#nav2-toggle {
  display: none;
}
#nav2-toggle:checked + ul {
  display: block;
}


a.dropbtn {
	cursor: default;
}

.dropbtn:hover ~ .dropdown_content,
.dropdown_content:hover {
  max-height: 300px;
}


/* main */
main {
	clear: left;
	border-width: 0;
	overflow: hidden;
	/* padding: 0 20px 20px 20px; */
	padding: 0 90px 20px 90px;
	min-height: 30em;

	@media (max-width: $width-mobileMenu) {
		padding: 0 15px 20px 15px;
	}

	&:before {
		content: " ";
		margin-top: 10px;
		margin-left: -90px;
		margin-bottom: 20px;
		display: block;
		width: calc(100% + 180px);
		background-position: top left;
		background-repeat: no-repeat;
		background-size: cover;

		@media (max-width: $width-mobileMenu) {
			margin-left: -15px;
			width: calc(100% + 30px);
		}

	}

	& > div {
		width: 100%;
	}
}

/* in-page nav */
.pageNav {
	text-align: left;
	padding: 40px 0 20px 0;
	margin-left: auto;
	margin-right: auto;
	//display: block;
	font-size: 0;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	//align-items: flex-start;
	gap: 30px 20px;

	@media (max-width: $width-mobileMenu) {
		padding-top: 10px;
	}

	a {
		flex: 0 0 120px;
		display: inline-block;
		vertical-align: top;
		margin: 0;
		//margin-left: 30px;
		text-align: center;
		//padding-bottom: 30px;
		font-size: 0.95rem;

		@media (max-width: $width-mobileMenu) {
			font-size: 0.8rem;
			flex: 0 0 100px;
			//margin-left: 20px;
		}

		@media (max-width: 500px) {
			flex: 0 0 70px;
			//margin-left: 20px;
			font-size: 0.5rem;
		}

		&.current {
			color: $color-orange;
		}
		&.noclick {
			cursor: default;
			pointer-events: none;
		}

		/*
		&:first-child {
			margin-left: 0;
		}

		&:nth-child(5) {
			@media (min-width: 931px) AND (max-width: 1120px) {
				margin-left: 0;
			}
		}

		&:nth-child(4) {
			@media (max-width: 930px) {
				margin-left: 0;
			}
		}
		*/
	}

	img {
		width: 100%;
		margin-bottom: 0.8rem;
	}

	span {
		display: block;
		text-transform: uppercase;
		font-weight: bold;
		text-align: center;
	}
}
/*
!* in-page nav, 5 elements *!
.pageNav.pageNav_5 {
	width: 920px;

	!* drop to 4 across *!
	@media (max-width: 1120px) {
		width: 730px;
	}

	!* drop to 3 across *!
	@media (max-width: 930px) {
		width: 540px;
	}

	!* progressively smaller icons *!
	@media (max-width: $width-mobileMenu) {
		width: 460px;
	}

	@media (max-width: 510px) {
		width: 340px;
	}

	@media (max-width: 390px) {
		width: 285px;
		padding-bottom: 30px
	}
}

!* in-page nav, 4 elements *!
.pageNav.pageNav_4 {
	width: 730px;

	!* drop to 3 across *!
	@media (max-width: 930px) {
		width: 540px;
	}

	!* progressively smaller icons *!
	@media (max-width: $width-mobileMenu) {
		width: 460px;
	}

	@media (max-width: 510px) {
		width: 340px;
	}

	@media (max-width: 390px) {
		width: 285px;
		padding-bottom: 30px
	}
}
!* in-page nav, 3 elements *!

.pageNav.pageNav_3 {
	width: 540px;

	!* progressively smaller icons *!
	@media (max-width: $width-mobileMenu) {
		width: 460px;
	}

	@media (max-width: 510px) {
		width: 340px;
	}

	@media (max-width: 390px) {
		width: 285px;
		padding-bottom: 30px
	}
}
*/

/* footers */
footer {
	padding-left: 90px;
	padding-right: 90px;

	@media (max-width: 700px) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

/* $mq-info-1col: "(max-width: 760px)"; */
$mq-info-1col: "(max-width: 920px)";

.info {
	position: relative;
	width: 100%;
	display: inline-block;
	background: #689fa3;
	padding-bottom: 20%;
	font-size: 2rem;

	* {
		color: white;
		font-size: 1.0rem;
		font-family: #{$font-opensans};
		font-weight: normal;

		@media #{$mq-info-1col} {
			font-size: 1.3rem;
		}

		@media (max-width: 550px) {
			font-size: 1.0rem;
		}

	}
	h2 {
		margin: 1rem 0 0 0;
		padding: 0;
	}
	&:before {
		content: " ";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-image: url("../img/swash.png");
		background-repeat: no-repeat;
		background-position: 50% 70px;
		background-size: 25%;
		pointer-events: none;

		@media #{$mq-info-1col} {
			background-size: 60%;
			background-position: 50% 20px;
		}
	}
	&:after {
		content: " ";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		opacity: 0.6;
		background-image: url("../img/mockup/contact-bg.png");
		background-repeat: no-repeat;
		background-position: 50% 0;
		background-size: cover;
		pointer-events: none;

		@media #{$mq-info-1col} {
			background-position: 52% -10%;
			background-size: auto 60%;
		}
	}
	.col {
		width: 33.3%;
		display: inline-block;
		float: left;

		&:first-child {
			padding: 3% 0 0 3%;

			@media #{$mq-info-1col} {
				margin-top: 115px;
				padding-left: 0;
			}
		}
		&:last-child {
			text-align: center;
		}

		@media #{$mq-info-1col} {
			width: 90%;
			display: block;
			float: none;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}

		@media (max-width: 650px) {
			width: 98%;
		}
	}
}

.hours, .info h2, .support, .video, .subscribe a {
	text-transform: uppercase;
}
.hours {
	font-size: 0.85rem;
	font-family: #{$font-opensans};
	font-weight: normal;
	line-height: 1.6;

	@media #{$mq-info-1col} {
		font-size: 1.2rem;
	}
}

.contact {
	margin-top: 3rem;
	line-height: 1.6;

	@media #{$mq-info-1col} {
		margin-top: 1.5rem;
		margin-bottom: 1.0rem;
	}

	h2 {
		font-weight: normal;
	}
}
.chamber {
	margin-top: 1em;

	span {
		display: block;
	}

	img {
		width: 83%;
		margin-left: -2%;

		@media #{$mq-info-1col} {
			margin-left: auto;
			margin-right: auto;
			width: 40%;
			min-width: 200px;
		}
	}
}
.support a {
	display: block;
	margin: 200px auto 0 auto;
	padding: 1rem;
	border: 1px solid white;
	width: 75%;
	text-align: center;

	@media #{$mq-info-1col} {
		margin-top: 30px;
	}
}

/*----------SOCIAL STYLES----------*/

.social {
	text-align: center; margin-top: 40px;

	* {
		font-size: 0 !important;
	}
}
.share {
	overflow: hidden;
	padding-left: 0px;
}
/*
.sprite { display: inline-block; background: url("../img/social-sprite.png") no-repeat top center; width: 50px; height: 50px; background-size: 600% 100%; }
.sprite.social_email { background-position: 100% 0; }
.sprite.social_facebook { background-position: 0 0; }
.sprite.social_google { background-position: 60% 0; }
.sprite.social_pinterest{ background-position: 20% 0;}
.sprite.social_share { background-position: 60% 0; cursor: pointer; margin-right: 5px;}
*/
.sprite {
	display: inline-block;
	width: 40px;
	height: 36px;
	box-sizing: border-box;
	padding-right: 5px;

	&.share {
		width: 50px;
	}

	a {
		display: inline-block;
		background: url("../img/social-sprite2.png") no-repeat top center;
		width: 40px;
		height: 36px;
		background-size: 1400% 100%;
	}

	.social_email { background-position: 0 0; }
	.social_facebook { background-position: 2 / 13 * 100% 0; }
	.social_share { background-position: 4 / 13 * 100% 0; cursor: pointer;}

	.social_facebook_share { background-position: 9 / 13 * 100% 0; }
	.social_pinterest_share { background-position: 12 / 13 * 100% 0;}
	.social_email_share { background-position: 7 / 13 * 100% 0; }
}
.shareLinksWrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	height: 36px;
	width: 0;
}
.share .shareLinks {
	margin-left: -135px;
	transition: all 0.4s linear;
}
.share:hover .shareLinksWrapper, .social_share:active .shareLinksWrapper, .social_share:focus .shareLinksWrapper {
	width: initial;
}
.share:hover .shareLinks, .social_share:active .shareLinks, .social_share:focus .shareLinks {
	margin-left: 0;
}

.video {
	h2 {
		width: 70%;
		line-height: 1.7;
		margin: 2rem auto 0.5rem auto;
		font-family: #{$font-opensans};
		font-weight: bold;

		@media #{$mq-info-1col} {
			font-size: 0.8rem;
		}

		@media (max-width: 500px) {
			width: 90%;
		}
	}
	img {
		@media #{$mq-info-1col} {
			width: 100%;
		}
	}
	.videoWrapper {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		width: 200px;
		margin-left: auto;
		margin-right: auto;

		@media #{$mq-info-1col} {
			width: 60%;
		}

		@media (max-width: 500px) {
			width: 80%;
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

		}

	}
}

.subscribe {
	h2 {
		font-family: #{$font-garamond};
		text-transform: capitalize;
		margin: 2rem auto 0 auto;
		font-size: 1.6rem;
		line-height: 1.2;
		font-weight: normal;

		@media (max-width: 800px) {
			font-size: 1.3rem;
		}
	}
	a {
		display: block;
		margin-top: 1rem;
		font-size: 0.9rem;
		font-family: #{$font-opensans};
		font-weight: bold;
		background-color: #00727b;
		padding: 10px;
		width: 70%;
		margin-left: auto;
		margin-right: auto;

		&:hover {
			color: $color-orange;
		}

		@media #{$mq-info-1col} {
			width: 90%;
		}
	}
}

.inspiration {
	margin-top: -2px;

	img {
		width: 80%;

		@media #{$mq-info-1col} {
			width: 96%;
		}
	}
}
.quote, .credits {
	background-color: $color-gold;
	text-align: center;
}

.quote {
	border: 1px solid $color-gold;

	blockquote {
		font-family: #{$font-garamond};
		padding: 2rem 0 0 0;
		font-size: 3rem;
		color: white;
		width: 90%;
		line-height: 1.3;
		margin-left: auto;
		margin-right: auto;

		@media (max-width: 500px) {
			font-size: 2.3rem;
		}
		@media #{$mq-smallest} {
			font-size: 2.4rem;
			width: 86%;
		}
	}
	cite {
		font-family: #{$font-opensans};
		font-style: normal;
		color: white;
		font-size: 1.5rem;
		padding: 0;

		@media (max-width: 500px) {
			font-size: 1.2rem;
			margin-top: -1rem;
			margin-left: auto;
			margin-right: auto;
			display: block;
			width: 9.5em;
		}
	}
}

.credits {
	padding: 40px 0 50px 0;

	&, a {
		text-transform: uppercase;
		font-size: 1rem;
		color: #b06d13;
		position: relative;
	}

/*
	&:after {
		content: " ";
		background-image: url("../img/mockup/bottom-photo.png");
		margin-top: 2rem;
		display: block;
		width: 100%;
		padding-bottom: 57.6%;
		background-position: bottom left;
		background-repeat: no-repeat;
		background-size: cover;

		@media (max-width: 800px) {
			padding-bottom: 75%;
		}
		@media #{$mq-smallest} {
			margin-top: 1.5rem;
			padding-bottom: 160%;
			background-position: 50% 0%;
			background-size: 300%;
		}
	}
*/
}

/* general styles */
form {
	/* font-size: 0; */

	fieldset {
		margin-bottom: 2rem;
	}
	legend {
		font-size: 2rem;
		padding: 0 1rem 1rem 1rem;
		text-decoration: underline;
	}
	label, input, select, button, textarea {
		font-size: 1.2rem;
		outline: none;

		@media (max-width: 500px) {
			font-size: 1.0rem;
		}
	}
	label {
		width: 9em;
		display: inline-block;
		margin-left: 1em;
		vertical-align: top;
	}
	input {
		margin: 0 0.2em 0.4rem 0;
		/* width: 13em; */
	}
	textarea {
		width: 20em;
	}
}

.no-js .lazyload {
	display: none !important;
}

[class^="icon-"]:before, [class*=" icon-"]:before, button i {
 font-family: "actions";
 font-style: normal;
 font-weight: normal;
 speak: none;

 display: inline-block;
 text-decoration: inherit;
 width: 0.85em;
 margin-right: .2em;
 text-align: center;
 /* opacity: .8; */

 /* For safety - reset parent styles, that can break glyph codes*/
 font-variant: normal;
 text-transform: none;

 /* fix buttons height, for twitter bootstrap */
 line-height: 1em;

 /* Animation center compensation - margins should be symmetric */
 /* remove if not needed */
 margin-left: .2em;

 /* you can be more comfortable with increased icons size */
 /* font-size: 120%; */

 /* Font smoothing. That was taken from TWBS */
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;

 /* Uncomment for 3D effect */
 /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

i[class*="icon-"]:not(.noclick) {
	transition: color 0.2s ease-in;
	cursor: pointer;

	&:hover {
		color: $color-green;
	}

	&.icon-delete:hover {
		color: red;
	}
}

.textButton {
	border: none;
	background: none;
}

#searchForm {
	margin-top: 3px;
	font-size: 1.2rem;
	float: right;
	display: none;
}
form[name=search] {
	position: relative;
	margin-top: 15px;
}
.autocomplete {
	position: absolute;
	background-color: white;
	border: 1px solid #333;
	width: 90%;
	left: 5px;
	top: 25px;
	padding: 0 5px 5px 5px;
	text-align: left;
	display: none;

	a {
		display: block;
		color: #191b46;
		text-decoration: none;

		&:hover {
			color: #36b4b3;
		}
	}
}

form.feedback {
	label.validator-error {
		display: inline-block;
		width: auto !important;
		color: black;
		font-size: 90%;
		background-color: $color-orange;
		padding: 1px 4px;
		margin: 5px 0 5px 11em;
		border-radius: 5px;
		font-weight: bold;
		position: relative;
	}

	textarea[name=course_title] {
		width: 21em;
		max-width: 80%;

		@media (max-width: 900px) {
			max-width: 62%;
		}
		@media (max-width: 600px) {
			max-width: none;
			width: 13em;
		}
	}
}
.valid {
	border: 1px solid $color-green !important;
}
label.validator-error:after {
	bottom: 100%;
	left: 0;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(216, 132, 46, 0);
	border-bottom-color: $color-orange;
	border-width: 10px;
	margin-left: 10px;
	margin-top: 10px;
}
label.validator-error.top-left:after {
	bottom: 100%;
	left: 0;
}
label.validator-error.top-right:after {
	bottom: 100%;
	left: 85%;
}
label.validator-error.bottom-left:after {
	bottom: 0;
	left: 0;
	margin-bottom: -20px;
	border-bottom-color: transparent;
	border-top-color: $color-orange;
}
.validator-error:not(fieldset) {
	border: 1px solid $color-orange !important;
}
fieldset.validator-error {
	background-color: lighten($color-orange, 40%);
}
.date-mask {
	width: 8em !important;
}
button[disabled] {
	opacity: 0.7;
	cursor: not-allowed;
}

div.ws-po-box {
	background-color: $color-orange;
	color: white
}
.ws-po-arrowbox {
	border-bottom-color: $color-orange !important;
}
/*
.user-error {
	border-color: $color-orange;
}
*/

ul.parsley-errors-list {
	margin: 0 0 0 7em;
	list-style-type: none;
	width: auto;

	li {
		color: black;
		font-size: 80%;
		background-color: $color-orange;
		padding: 1px 4px;
		margin: 0 0 5px 0;
		border-radius: 5px;
		font-weight: bold;
		display: inline-block;
		position: relative;

		&:after {
			bottom: 100%;
			left: 0;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(216, 132, 46, 0);
			border-bottom-color: $color-orange;
			border-width: 9px;
			margin-left: 9px;
			/* margin-top: 10px; */
		}
	}
}
fieldset + ul.parsley-errors-list {
	margin-left: 8em;
}
fieldset.associated ul.parsley-errors-list {
	margin-left: 0;
	padding: 0;
}
.parsley-success:not(.customCheckbox):not(.customRadio) {
	border: 1px solid $color-green !important;
}
.parsley-error:not(fieldset):not(.customCheckbox):not(.customRadio) {
	border: 1px solid $color-orange !important;
}
fieldset.parsley-error {
	background-color: lighten($color-orange, 40%);
	margin-bottom: 10px !important;
}
.addressMissing {
	font-style: italic;
	opacity: 0.4;
}
.studentList, .registrantList {
	column-count: 2;

	p {
		break-inside: avoid;
	}
}

.printedPerson {
	margin-bottom: 2em;
	break-inside: avoid;

	p.notes {
		margin: 0;
		padding: 0.5em;
		background: #eee;
		border: 1px solid #333;
		width: 90%;
	}
}

/*.user-success, */.customCheckbox.parsley-error, .customCheckbox.parsley-error, .customRadio.parsley-error, .customRadio.parsley-error {
	border: none !important;
}
pre {
	white-space: pre-wrap;
	word-wrap: break-word;
}

.noRatings {

	i {
		opacity: 0.5;
		cursor: not-allowed;
	}
}

$unchecked-star: '\2606';
$zunchecked-star: '\e818';
$unchecked-color: #888;
$checked-star: '\2605';
$zchecked-star: '\e817';
$checked-color: #e52;

.star-cb-group {
	display: inline-flex;
	font-family: actions;
	/* remove inline-block whitespace */
	font-size: 0;
	* {
		font-size: 2rem;
	}
	/* flip the order so we can use the + and ~ combinators */
	unicode-bidi: bidi-override;
	direction: rtl;
	& > input {
		display: none;
		& + label {
			/* only enough room for the star */
			display: inline-block;
			overflow: hidden;
			text-indent: 9999px;
			width: 1em;
			white-space: nowrap;
			cursor: pointer;
			line-height: 0.8;
			padding-bottom: 0.5rem;
			&:before {
				display: inline-block;
				text-indent: -9999px;
				content: $unchecked-star;
				color: $unchecked-color;
			}
		}
		&:checked ~ label:before,
			& + label:hover ~ label:before,
			& + label:hover:before {
			content: $checked-star;
			color: #e52;
			text-shadow: 0 0 1px #333;
		}
	}

	/* the hidden clearer */
	& > .star-cb-clear + label {
		text-indent: -9999px;
		width: .5em;
		margin-left: -.5em;
	}
	& > .star-cb-clear + label:before {
		width: .5em;
	}

	&:hover > input + label:before {
		content: $unchecked-star;
		color: $unchecked-color;
		text-shadow: none;
	}
	&:hover > input + label:hover ~ label:before,
	&:hover > input + label:hover:before {
		content: $checked-star;
		color: $checked-color;
		text-shadow: 0 0 1px #333;
	}
	label {
		margin: 0;
		width: 32px !important;
	}
}

ul.ratings {
	margin-top: 10px;

	li > span:first-of-type, .stars {
		display: inline-block;
		width: 8em;
	}

	i {
		color: $color-orange;
	}
}

button.link {
	background: none;
	margin: 0;
	padding: 0;
	border: none;
	color: $color-orange;
	font-family: inherit;
	font-size: inherit;
	text-decoration: none;
	transition: color 0.2s ease-in;

	&:hover {
		color: $color-green;
	}
}

body.font-override * {
	font-family: sans-serif !important;
}

.print-only, .printable .no-print {
	display: none;
}

.printable {
	//.print-only, h1, h2, h3, h4, h5, h6 {
	.print-only {
		display: unset;
		//display: block;
		visibility: visible;
		//border: 1px solid red;
		//font-family: sans-serif !important;
		//color: red;
		//text-align: left;
		//max-width: 100%;
	}

	h1, h2, h3, h4, h5, h6 {
		font-family: serif;
	}

	h2, h2 * {
		font-size: 18pt !important;
	}
}
