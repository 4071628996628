.login {
	overflow: hidden;
	padding: 20px 0;

	form {
		margin: 50px auto 0 auto;
		font-size: 1.5rem;
		width: 16em;
		float: none;
		overflow: hidden;
	}
	label {
		display: inline-block;
		line-height: 1.7;
		float: left;
		clear: left;
	}
	input {
		margin-bottom: 10px;
		display: inline-block;
		line-height: 1.2;
		width: 10em;
		float: right;
	}
	button {
		clear: both;
		margin-top: 10px;
		float: right;
	}
}

.loginBlock {
	background-color: #fbf2ea;
	margin-bottom: 2em;
	padding: 0.5em 1em;

	h3 {
		margin: 0;
	}

	.note {
		margin-top: 0;
		font-size: 14px;
	}
}
