main.cart {
  form {
    margin-left: auto;
    margin-right: auto;

    fieldset {
      padding: 10px 0 5px 0;

      legend {
        font-size: 1.5rem;
        text-decoration: none;
        padding: 0 0.5em;
      }

      fieldset {
        padding: 0;
        border: 0;
      }
    }

    label {
      display: block;
      width: initial;
      float: left;
      clear: left;

      & > span:first-child:not(.inputNote) {
        width: 9em;
        display: inline-block;
        vertical-align: top;
      }

      span.inputNote {
        font-size: 70%;
        width: calc(20em / 0.7);
        margin-left: calc(9em / 0.7);
        margin-top: 7px;
        margin-bottom: 5px;
        line-height: 1.2;
        display: block;
      }

      &.detached {
        display: inline-block;
        width: auto;

        & > span:first-child {
          width: auto;
          margin-right: 1em;
        }
      }


      fieldset {
        margin-left: -5px;
      }
    }

    div.associated {
      display: inline-block;
      width: auto;
      margin: 0;
    }

    textarea {
      /* width: 100%; */
      max-width: 30em;
    }

    table.cart {
      width: 100%;
      zwidth: calc(100% - 60px);
      background-color: #fbf2ea;
      border-style: solid;
      border-color: #fbf2ea;
      border-width: 10px 20px 10px 20px;
    }
    th {
      text-align: left;
      font-family: garamond,serif;
    }
    th, td {
      padding-right: 15px;
    }
    thead tr:nth-of-type(2) th:first-child, td:first-child {
      padding-left: 20px;
    }
    th:nth-of-type(3), td:nth-of-type(3) {
      text-align: right;
    }
    table.cart h3 {
      margin: 0;
      font-family: garamond,serif;
    }
    table.cart h3:before {
      content: "\2022";
      color: #d8842e;
      margin-right: 0.25em;
    }
    .linkButton {
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      font-size: 70%;
      color: #d8842e;
      transition: color 0.5s;
    }
    .linkButton:hover {
      color: #1a6e74;
    }
    #paymentForm, #checkPaymentForm {
      display: none;
    }
    div.payMethod {
      border: 1px solid black;
      margin: 0 20px 20px 20px;
      padding: 10px 10px 0 10px;
      border-radius: 5px;
    }
    h2.payMethod {
      font-size: 1.8rem;
    }
    .cardSecurityNote {
      margin-top: 0;
    }
  }
  table.cart {
    width: 100%;
    zwidth: calc(100% - 60px);
    background-color: #fbf2ea;
    border-style: solid;
    border-color: #fbf2ea;
    border-width: 10px 20px 10px 20px;
    min-height: 150px;
    
    .enrollments td {
      padding-bottom: 15px;
    }
  }
  th {
    text-align: left;
    font-family: garamond,serif;
  }
  th, td {
    padding-right: 15px;
  }
  thead tr:nth-of-type(2) th:first-child, td:not(.subhead) {
    padding-left: 20px;
  }
  th:nth-of-type(3), td:nth-of-type(3) {
    text-align: right;
  }
  table.cart h3 {
    margin: 0;
    font-family: garamond,serif;
  }
  table.cart h3:before {
    content: "\2022";
    color: #d8842e;
    margin-right: 0.25em;
  }
  .linkButton {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    font-size: 70%;
    color: #d8842e;
    transition: color 0.5s;
  }
  .linkButton:hover {
    color: #1a6e74;
  }
  #paymentForm, #checkPaymentForm {
    display: none;
  }
  div.payMethod {
    border: 1px solid black;
    margin: 0 20px 20px 20px;
    padding: 10px 10px 0 10px;
    border-radius: 5px;
  }
  h2.payMethod {
    font-size: 1.8rem;
  }
  .cardSecurityNote {
    margin-top: 0;
  }

  .StripeElement {
    display: inline-block;
    padding: 3px;
    border: 1px solid #a9a9a9;
  }
  #card-number {
    width: 11em;
  }
  #card-expiry {
    width: 5em;
  }
  #card-cvc {
    width: 4em;
  }

  #donation {
    margin-right: -2px;
    text-align: right;
  }

  .hidden {
    display: none;
  }

  [v-cloak] {
    display: none !important;
  }
}

