main.credits {
	background-color: white;
	text-transform: none;
	
	div, a {
		color: black;
		font-family: $font-opensans;
		text-transform: none;
		line-height: 1.8;
		font-size: 150%;
	}
	
	a {
		display: block;
		margin-bottom: 2em;
	
		&:hover {
			color: $color-green;
		}	
	}
}
