main.search-results {
	li {
		margin-bottom: 0.5em;
		
		a {
			font-weight: bold;
		}
		
		.url {
			display: block;
			font-size: 80%;
			font-weight: normal;
		}
		
		.contentSnippet {
			b {
				color: $color-orange;
				font-weight: bold;
			}
		}
	}
}
