/* cake index */
table.list {
	border-collapse: collapse;
	margin: 50px auto;
	font-size: 1.2rem;
}
.list {
	th, td {
		text-align: left;
		padding: 10px;
	}

	td {
		border-bottom: 1px dashed #333;
		.cost {
			text-align: right;
		}
		.links {
			font-size: 0.9rem;
		}
	}
	tfoot td {
		border-bottom: none;
		text-align: center;
	}
}

.list td {
	max-width: 10em;
	word-wrap: break-word;
}

.pagination {
	padding: 0;
	list-style: none;
	li {
		display: inline-block;
		margin-right: 0.5em;
		&:first-child {
			float: left;
		}
		&:last-child {
			float: right;
		}

		a {
			padding: 4px;
		}

		&.active a {
			cursor: default;
			pointer-events: none;
			color: white !important;
			background-color: $color-green;
		}
	}
	.disabled {
		display: none;
	}
}
/* cake view */
table.detail {
	border-collapse: collapse;
	margin: 50px auto;
	font-size: 1.2rem;
}
.detail th, .detail td {
	text-align: left;
	padding: 10px;
}
.detail td:first-child {
	font-weight: bold;
}

/* cake edit */
form.edit, form.feedback, main.enrollments form {
	margin-left: auto;
	margin-right: auto;

	fieldset {
		padding: 10px 0 5px 0;

		legend {
			font-size: 1.5rem;
			text-decoration: none;
			padding: 0 0.5em;
		}

		fieldset {
			padding: 0;
			border: 0;
		}
	}

	label {
		display: block;
		width: initial;
		float: left;
		clear: left;

		& > span:first-child:not(.inputNote) {
			width: 9em;
			display: inline-block;
			vertical-align: top;
		}

		span.inputNote {
			font-size: 70%;
			width: calc(20em / 0.7);
			margin-left: calc(9em / 0.7);
			margin-top: 7px;
			margin-bottom: 5px;
			line-height: 1.2;
			display: block;
		}

		&.detached {
			display: inline-block;
			width: auto;

			& > span:first-child {
				width: auto;
				margin-right: 1em;
			}
		}


		fieldset {
			margin-left: -5px;
		}
	}

	div.associated {
		display: inline-block;
		width: auto;
		margin: 0;
	}

	textarea {
		/* width: 100%; */
		max-width: 30em;
	}

}

form.feedback {
	width: 84%;

	span {
		font-size: 1.2rem;
	}

	fieldset.underFullWidth {
		margin-left: 2rem !important;
	}

	h2 {
		width: 100% !important;
		font-size: 1.6rem;
	}
	p {
		width: 100% !important;
	}
	h3 {
		text-transform: uppercase;
		font-size: 1.4rem;
	}
	.termDue {
		display: inline-block;
		margin-left: 2em;
	}
	.fullWidth span {
		width: 100% !important;
		display: block;
	}
	.inlineInput {
		display: inline-block;
		margin-left: 1em;
	}
	.inputGroup {
		display: inline-block;
		margin-left: 1rem;
	}
	fieldset {
		border: none;
		margin: 0 0 0 1em;
		padding: 0;

		&.inputGroup {
			padding: 0.25em 0.25em 0.25em 0;

			&:not(.associated) {
				margin-left: 0;
			}
		}

		legend {
			font-size: inherit;
			margin: 0;
			padding: 0;
		}
	}
	label fieldset {
		margin: 0;
	}
	.book_detail {
		border: 1px solid #333;
		margin-left: 5%;
		margin-top: 10px;
		width: 80%;
	}
	#addText {
		font-style: normal;
		cursor: pointer;
	}
	input, textarea, select {
		border: none;
		background-color: #e6fafb;
	}
	input[type=number] {
		width: 3em;
	}

	.days_times td {
		padding-right: 0.5rem;
	}
	.days_times td:first-child {
		padding-right: 1rem;
	}

	@-webkit-keyframes pulse {
		0% {
			box-shadow: inset 0px 0px 2em 1px rgba(214,134,15,1);
		}
		100% {
			box-shadow: none;
		}
	}

	.pulse {
		animation-name: pulse;
		animation-duration: 200ms;
		animation-iteration-count: 1;
		animation-timing-function: ease-in-out;
	}

	.customCheckbox {
		width: 15px;
		position: relative;
		margin: 0 0 0 0;
		display: inline-block;

		label {
			margin-left: 0;
			margin-top: 5px;
			width: 15px;
			height: 15px;
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			background: darken(#e6fafb, 10%); /* baf1f4 */
			border: 1px solid #ccc;
			border-radius: 4px;

			&:after {
				content: '';
				width: 6px;
				height: 3px;
				position: absolute;
				top: 3px;
				left: 2px;
				border: 3px solid #333;
				border-top: none;
				border-right: none;
				background: transparent;
				opacity: 0;
				transform: rotate(-45deg);
			}

			&:hover::after {
				opacity: 0.5;
			}
		}
		input[type=checkbox] {
			margin-top: 5px;
			visibility: hidden;
			&:checked + label:after {
				opacity: 1;
			}
		}
	}
	.customCheckboxLabel {
		display: inline;
		margin-left: 5px;
	}

	div.customRadio {
		/*
		width: 28px;
		height: 28px;
		zmargin: 20px auto;
		zborder-radius: 50px;
		*/
		zbackground: #fff;
		position: relative;
		display: inline-block;
		margin-left: 0.25em;
		white-space: nowrap;
		zwidth: 13px;

		&.multiline {
			white-space: normal;
			width: 100%;
			margin-bottom: 10px;
		}

		label {
			cursor: pointer;
			position: absolute;
			width: 20px !important;
			margin-left: -8px !important;
			height: 20px;
			border-radius: 50px;
			left: 4px;
			top: 4px;
			background: darken(#e6fafb, 10%);

			&:after {
				opacity: 0;
				content: '';
				position: absolute;
				width: 14px;
				height: 14px;
				background: darken(#e6fafb, 50%);
				border-radius: 50px;
				top: 3px;
				left: 3px;
			}

			&:hover::after {
				opacity: 0.3;
			}
		}

		input[type=radio] {
			vertical-align: top;
			margin-top: 7px;
		}

		input[type=radio]:checked + label:after {
			opacity: 1;
			zwidth: auto;
		}
	}
	.customRadioLabel {
		display: inline-block;
		margin-right: 1.5em;
		width: calc(100% - 3em);
	}


}

#cityStateZIP, #expiration {
	border: none;
	padding: 0;
	width: auto;
	display: inline-block;
}

.inputPrefix {
	margin-right: 0.125em;
}


.icon-view:before { content: '\e801'; }
.icon-add:before { content: '\e802'; }
.icon-edit:before { content: '\e803'; }
.icon-delete:before { content: '\e804'; }
.icon-restore:before { content: '\e805'; }
.icon-save:before { content: '\e806'; }
.icon-admin:before { content: '\e807'; }
.icon-instructor:before { content: '\e808'; }
.icon-student:before { content: '\e809'; }
.icon-enroll:before { content: '\e80a'; }
.icon-comment:before { content: '\e80b'; }
.icon-up:before { content: '\e80c'; }
.icon-down:before { content: '\e80d'; }
.icon-blank:before { content: '\e80e'; visibility: hidden; }
.icon-cart:before { content: '\e80f'; }
.icon-email:before { content: '\e810'; }
.icon-book:before { content: '\e811'; }
.icon-hash:before { content: '\e812'; }
.icon-chart:before { content: '\e813'; }
.icon-ok:before { content: '\e814'; }
.icon-question:before { content: '\e815'; }
.icon-alert:before { content: '\e816'; }
.icon-star:before { content: '\e817'; }
.icon-star-empty:before { content: '\e818'; }
.icon-star-half:before { content: '\e819'; }
.icon-unlock:before { content: '\e81a'; }
.icon-at:before { content: '\e81b'; }
.icon-wait:before { content: '\e81c'; }

@keyframes working {
	//0% {
	//	//transform: scale3d(1, 1, 1);
	//	//font-size: 100%;
	//	color: blue;
	//	transform: rotate(0deg);
	//}
	//
	//
	//37.5% {
	//	//transform: scale3d(1.05, 1.05, 1.05);
	//	//font-size: 120%;
	//	color: red;
	//	transform: rotate(180deg);
	//}

	75% {
		//transform: scale3d(1, 1, 1);
		//font-size: 100%;
		color: blue;
		transform: rotate(360deg);
	}

	100% {
		//transform: scale3d(1, 1, 1);
		//font-size: 100%;
		color: blue;
		transform: rotate(360deg);
	}
}

i.working:before {
	animation-name: working;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-delay: 0.5s;
	animation-timing-function: ease-in-out;
}


.pageEditLink {
	font-size: 1.4rem;
	display: inline-block;
	padding: 0.2rem 0.4rem;
	border: 1px dotted $color-green;
	margin-bottom: 2rem;
	border-radius: 0.3rem;
}

.deleted, .deleted input {
	background-color: #ccc;
	/* text-decoration: line-through; */
}

/* ajax feedback */
.pending {
	background: none;
	box-shadow: inset 0px 0px 2em 1px rgba(255, 255,   0,1);
}

@-webkit-keyframes success {
	0% {
		box-shadow: inset 0px 0px 2em 1px rgba(  0, 255,   0,1);
	}
	100% {
		box-shadow: none;
	}
}

.success {
	background-color: none;
	animation-name: success;
	animation-duration: 1250ms;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
}

.error {
	box-shadow: inset 0px 0px 2em 1px rgba(255, 0,   0,1);
}

.msgs {
	padding: 0.5em;
	background: $color-orange;
	margin-bottom: 1em;

	li {
		margin-left: 2em;
	}
}

.selectize-control {
	display: inline-block;
	width: 20em;
}

.reviewBlock {
	width: 80%;
	padding: 0.5em;
	margin: 1em 0;
	background-color: #fbf2ea;

	h4 {
		margin: 5px 0;
	}

	ul.ratings {
		list-style: none;
		padding-left: 0;
		margin: 0;

		li > span:first-of-type {
			width: 5em;
		}
	}
}

.printable .reviewBlock {
	border-bottom: 1px solid #999999;
	padding: 1.0em 0.5em;
	margin: 0;
}

form.edit .rteWrapper {
	float: left;
	clear: left;
	width: calc(100% - 3em);
	margin-bottom: 15px;

	label {
		width: 100%;

		.cke {
			margin-left: 1em;
		}
	}
}
