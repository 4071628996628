#membershipFilter {
	margin-bottom: 10px;
	
	label {
		margin-right: 1em;
		
		span {
			margin-left: 0.3em;
		}
	}
}
.students {
	.datatable.students {
		width: auto;
		
		/*
		th:nth-child(5), td:nth-child(5) {
			display: none;
		}
		*/

		td.actions {
			white-space: nowrap;
			text-align: center;
		}
	}

	
	.dt-buttons {
		clear: both;
		margin-top: 10px;
		
		&:before {
			content: "Save view as ";
			vertical-align: top;
			display: inline-block;
			margin-right: 0.4em;
			margin-top: 2px;
		}
	}
	
	.dt-button {
		padding: 3px 5px;
	}

}