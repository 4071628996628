main.courses {
	&:before {
		background-image: url("../img/courses.jpg");
		padding-bottom: 22.9%;
	}

	nav.terms {
		margin-bottom: 20px;

		a {
			display: inline-block;
			margin-right: 5%;
			font-family: #{$font-opensans};
			font-size: 1.4rem;

			&.current {
				color: black;
				cursor: default;
				pointer-events: none;
			}
		}
	}

	h2, nav.terms, article, .add, & > form > p, & > p, .snippet, .cke {
		width: 84%;
		margin-left: auto;
		margin-right: auto;

		@media (max-width: 500px) {
			width: 100%;
		}
	}

	article {
		padding: 1rem 1.3rem;
		background-color: #fbf2ea;
		margin-bottom: 1rem;
		font-family: #{$font-opensans};
		font-size: 1.3rem;
		font-weight: bold;

		@media (max-width: 500px) {
			padding: 0.5rem;

			.enrollmentDetails {
				padding-bottom: 10px;
			}
		}

		&.deleted {
			background-color: #eeeeee;
			opacity: 0.7;
		}

		h3, h4 {
			margin-top: 0;
			margin-bottom: 10px;
		}

		h3 {
			font-size: 120%;
			position: relative;
		}

		.deletedLabel {
			position: absolute;
			right: 0;
			color: $color-orange;
		}

		h4 {
			text-transform: uppercase;
		}

		time, div {
			display: block;
			font-size: 70%;
			margin-bottom: 10px;
		}

		button {
			font-size: 100%;

			icon {
				margin-left: 1em;
				margin-right: 0.5em;
			}
		}

/*
		span:not(:empty) + .actions {
			margin-left: 0.5em;
*/
		.enrollmentDetails {
			span:first-of-type:not(:empty) {
				margin-right: 0.5em;
			}
		}

		.actions {
			@media (max-width: 500px) {
				display: block;
				float: right;

				& > a, .enrollControl {
					display: block;
				}
			}

			a {
				display: inline-block;

				&:not(:first-of-type) {
					margin-left: 0.85em;
				}
			}
		}
	}

	.comments {
		h3 {
			color: $color-orange;
			margin: 1em 0 0.5em 0;
		}

		.comment {
			padding: 0.5em 0;
			border-bottom: 1px solid #333;
			width: 100%;
			background-color: inherit;
		}

		h4 {
			margin-top: 0;
		}

		.student {
			margin-right: 2em;
		}

		.date {
			font-size: 80%;
		}

		div {
			font-weight: normal;
		}
	}

	button.formLink {
		border: none;
		background: none;
		margin-left: 1em;
		text-decoration: none;
		color: black;
		transition: color 0.2s ease-in;

		&:hover {
			color: #{$color-green};
		}

		i {
			margin-right: 0.5em;
		}

		&.admin-disenroll {
			margin-left: 0;
		}
	}

}

.notificationCourseName {
	font-style: italic;
	text-decoration: underline;
}

