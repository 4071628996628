.top-bar-section {
	padding: 10px 5px;
	background-color: #7dacb0;
	color: white;
}

div.message {
	cursor: pointer;
	display: block;
	font-weight: normal;
	padding: 0 1.5rem 0 1.5rem;
	transition: height 300ms ease-out 0s;
	background-color: #a0d3e8;
	color: #626262;
	position: fixed;
	top: 65px;
	right: 15px;
	z-index: 999;
	overflow: hidden;
	height: 50px;
	line-height: 2.5em;
}

div.message:before {
	line-height: 0px;
	font-size: 20px;
	height: 12px;
	width: 12px;
	border-radius: 15px;
	text-align: center;
	vertical-align: middle;
	display: inline-block;
	position: relative;
	left: -11px;
	background-color: #FFF;
	padding: 12px 14px 12px 10px;
	content: "i";
	color: #a0d3e8;
}

div.message.error {
	background-color: #C3232D;
	color: #FFF;
}

div.message.error:before {
	padding: 11px 16px 14px 7px;
	color: #C3232D;
	content: "x";
}
div.message.hidden {
	height: 0;
}

.formLink {
	display: inline-block;
}

.formLink button, button.formLink {
	background: none;
	border: none;
	padding: 0;
	margin: 0;
}
.error-message {
	display: inline-block;
	color: red;
}
.cake-debug-output {
	font-size: 1.3rem;
}

.snippet, .slideshowWrapper, .editable {
	border: 1px dashed #444;
	position: relative;
	transition: border-color 0.2s ease-in;
	/* pointer-events: none; */
	
	&:hover {
		border-color: $color-green;
		border-style: dotted;
	}

	&:before {
		content: '\e803';
		font-family: "actions";
		position: absolute;
		top: -16px;
		left: 5px;
		cursor: pointer;
		/* pointer-events: all; */
		background: transparent;
		padding: 0 5px;
		transition: color 0.2s ease-in;
	}
	&:hover:before {
		color: $color-green;
	}
}
.snippet.active, .slideshowWrapper.active {
	/* pointer-events: all; */
	
	&:before {
		content: '\e806';
	}
}
